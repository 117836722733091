<!DOCTYPE html>
<html
  lang="en"
  xmlns:th="http://www.thymeleaf.org"
  xmlns:span="http://www.w3.org/1999/xhtml"
>
<head>
  <meta charset="UTF-8" />
  <title>D-Portal</title>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <style>
    .modal-container {
      max-width: 910px;
      border: 1px solid #aaa;
    }
    .modal-body {
      overflow: hidden !important;
    }
    .modal-container .content {
      overflow-y: hidden;
    }
    .modal-container .content {
      border: 1px solid #bbb;
    }

    #list {
      min-height: 720px;
    }
  </style>
</head>
<body>
<div class="docs-container off-canvas off-canvas-sidebar-show">
  <div class="docs-navbar">
    <a class="off-canvas-toggle btn btn-link btn-action" href="{{page}}#sidebar"
    ><i class="icon icon-menu"></i
    ></a>
  </div>
  <div class="docs-sidebar off-canvas-sidebar" id="sidebar">
    <div class="docs-brand">
      <a class="docs-logo" href="/home">
        <img src="assets/img/letter-d.png" style="width: 24px;" alt="daatguul">
        <label class="label label-secondary text-bold ml-1">PORTAL</label></a>
    </div>
    <menu-root></menu-root>
  </div>
  <a class="off-canvas-overlay" href="{{page}}#close"></a>
  <div class="off-canvas-content">
    <div class="docs-content" id="content">
      <div id="list">
        <h5 class="s-title">Баталгаажсан<a class="anchor text-success" href="{{page}}#list" aria-hidden="true">#</a>
        </h5>
        <div class="docs-demo columns pt-0">
          <div class="column col-12">
            <div class="filter">
              <input *ngFor="let org of orgs; let i = index;" type="radio" id="tag-{{i}}" class="filter-tag" name="filter-radio" hidden checked="{{i == selOrgId}}">

              <div class="columns">
                <div class="column col-9 col-xs-12">
                  <div class="filter-nav">

                    <button class="btn btn-sm btn-success" (click)="listModeChange(listMode==1?2:1)"><i class="icon {{listMode == 1 ? 'icon-menu':'icon-apps'}}"></i></button>
                    &nbsp;
                    <label class="chip p-2 {{selOrgId == 0 ? 'bg-success':''}}" (click)="setOrgId(0)">&nbsp;Бүгд&nbsp;</label>

                    <label class="chip p-2 {{selOrgId == org.id ? 'bg-success':''}}"  *ngFor="let org of orgs; let i = index;" (click)="setOrgId(org.id)">&nbsp;{{org.name.replace(' даатгал', '')}}&nbsp;</label>
                  </div>
                </div>

                <div class="column col-3 col-xs-12" *ngIf="profile && profile.role == 'ROLE_ADMIN'">
                  <div class="input-group mt-2"><span class="input-group-addon addon-sm">Агент</span>
                    <select class="form-select select-sm" [(ngModel)]="selAgentId" (change)="getInvoices(pageNum)">
                      <option value="0">Бүгд</option>
                      <option *ngFor="let agent of agents" value="{{agent.id}}">{{agent.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="filter-body columns" *ngIf="listMode == 1">
                <div class="column col-1 m-2" *ngIf="invoiceLoading">
                  <div
                    class="loading loading-sm float-left">
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="clearfix"></div>

                <div  class="column col-3 col-xs-6 filter-item" *ngFor="let invoice of invoices.invoiceDtoList" [attr.data-tag]="'tag-'+invoice.orgId">
                  <div class="card s-rounded" (click)="viewInvoice(invoice)">
                    <div class="card-header">
                      <div class="card-title">
                        <label class="label label-secondary label-rounded">
                          <i class="icon icon-people" style="font-size:10px;"></i>&nbsp;
                          <span class="text-bold" style="font-size:12px;">{{invoice.unit}} хүн</span>
                        </label>&nbsp;
                        <label class="label label-secondary label-rounded">
                          <i class="icon icon-time" style="font-size:10px;"></i>&nbsp;
                          <span class="text-bold" style="font-size:12px;">{{(invoice.endDate - invoice.startDate) / (24 * 3600 * 1000)}} өдөр</span>
                        </label>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="card-title text-bold text-small">{{invoice.orgName}}</div>
                      <div class="card-title text-gray text-small">{{invoice.serviceName}}</div>
                      <div class="card-subtitle text-bold text-large">{{invoice.finalAmount | currency:'₮'}}</div>
                      <div class="card-title text-gray text-small float-left">{{invoice.createdDate | date:'yyyy-MM-dd HH:mm'}}</div>
                    </div>
                    <div class="card-footer pt-2">
                      <a href="{{page}}#{{modal}}" class="btn btn-success btn-sm" (click)="viewInvoice(invoice)"><i class="icon icon-apps"></i></a>
                      <button class="btn btn-sm btn-error ml-1" *ngIf="profile.role == 'ROLE_ADMIN'" (click)="deleteInvoice(invoice)"><i class="icon icon-delete"></i></button>
                    </div>
                  </div>
                </div>

              </div>

              <div class="filter-body" *ngIf="listMode == 2">
                <div class="column col-1 m-2" *ngIf="invoiceLoading">
                  <div
                    class="loading loading-sm float-left">
                  </div>
                </div>
                <table class="table table-scroll table-striped table-hover text-small" *ngIf="!invoiceLoading">
                  <thead class="text-small text-dark">
                  <tr>
                    <th style="width: 3%;" class="text-center">
                      <span>#</span>
                    </th>
                    <th style="width: 3%;">Үйлдэл</th>
                    <th style="width: 10%">Компани</th>
                    <th style="width: 19%">Групп</th>
                    <th style="width: 8%">Тоо/өдөр</th>
                    <th style="width: 5%">Даатгал</th>
                    <th style="width: 12%" class="text-right">Хурааамж</th>
                    <th style="width: 12%" class="text-right">Валют(Ханш)</th>
                    <th style="width: 15%" *ngIf="viewMode==1">Агент</th>
                    <th style="width: 8%">Баталгаажсан</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let invoice of invoices.invoiceDtoList; let i=index;"  [style]="invoice.userId == profile.id ? 'font-style:normal':'font-style:italic;color:gray;'">
                    <td class="text-right">
                      <i class="icon icon-more-vert" *ngIf="(invoice.dkSent == 0)"></i>
                      <i class="icon icon-check" *ngIf="invoice.dkSent"></i>
                    </td>
                    <td>
                      <a href="{{page}}#{{modal}}" class="btn btn-success btn-sm" (click)="viewInvoice(invoice)"><i class="icon icon-apps"></i></a>
                      <button class="btn btn-sm btn-error ml-1" *ngIf="profile && profile.role == 'ROLE_ADMIN'" (click)="deleteInvoice(invoice)"><i class="icon icon-delete"></i></button>
                    </td>
                    <td>{{invoice.orgName}}</td>
                    <td>{{invoice.groupId == 0 && invoice.serviceId == 1 ? '-':invoice.groupName}}
                      <span *ngIf="invoice.discountValue > 0">({{invoice.discountValue}}%)</span>
                    </td>
                    <td>{{invoice.unit}} хүн/{{invoice.days}} өдөр</td>
                    <td>{{serviceShortName(invoice.serviceName)}}</td>
                    <td class="text-right">
                      <label class="label label-rounded {{invoice.finalAmount != invoice.amount ? 'label-warning':'label-success'}}">{{invoice.finalAmount | currency : '₮ '}}</label>
                    </td>
                    <td class="text-right">
                      <b>{{invoice.amountUSD | currency : '$ '}}</b> ({{invoice.rate | currency : '₮ '}})
                    </td>
                    <td *ngIf="viewMode==1">{{invoice.agentName}}</td>
                    <td>{{invoice.updatedDate |  date:'yyyy-MM-dd HH:mm'}}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="6"></td>
                    <td class="text-right"><label class="label label-rounded label-success text-bold">
                      {{invoices.amounts | currency : '₮ '}}</label></td>
                    <td colspan="4" class="text-right">

                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <select class="form-select select-sm" style="width: 50px;" (change)="getInvoices(pageNum)" [(ngModel)]="pageNum">
                        <option *ngFor="let item of pages" value="{{item.page-1}}">{{item.page}}</option>
                      </select>
                    </td>
                    <td colspan="6">
                      <select class="form-select select-sm" style="width: 120px;" [(ngModel)]="viewMode">
                        <option value="1">Дэлгэрэнгүй</option>
                        <option value="2">Хураангуй</option>
                      </select>
                    </td>
                    <td class="text-bold text-right" colspan="2">Нийт : {{invoices.rows}} мөр</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="modal modal-lg" id="{{modal}}">
        <a
          href="{{page}}#close"
          class="modal-overlay"
          aria-label="Close"
        ></a>
        <div class="modal-container">
          <div class="modal-header p-0">
            <a
              href="{{page}}#close"
              class="btn btn-clear float-right mt-2"
              aria-label="Close"
            ></a>
            <div class="modal-title h5 mt-2">Даатгалын бүртгэл</div>
          </div>
          <div class="modal-body p-0">
            <div class="columns" *ngIf="newInvoice.id == 0">
              <div class="column col-6 input-group">
                <span class="input-group-addon addon-sm" style="width: 120px;">Үйлчилгээ</span>
                <select class="form-select select-sm" disabled="{{paylinks.qr}}" [(ngModel)]="newInvoice.serviceId" (change)="createInvoice(newInvoice.serviceId)">
                  <option [ngValue]="0">Сонгох</option>
                  <option *ngFor="let service of services" [ngValue]="service.id">{{service.name}}</option>
                </select>

                <div class="float-left" *ngIf="gloading">
                  <div
                    class="loading float-left"
                    style="margin: 5px; margin-left: 15px;">

                  </div>
                </div>

              </div>
              <div class="column col-6">
                <div class="float-right">
                  <div class="input-group" *ngIf="!this.loading && !this.payLoading && paylinks.qr.length == ''">
                    <button class="form-input btn btn-sm" (click)="prevPage()"> << </button>
                    <button class="form-input btn btn-primary btn-sm" (click)="nextPage()"> >> </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div *ngIf="pageId == 1" class="column col-6">
                <div class="card" style="height: 500px;">
                  <div class="card-header">
                    <ul class="tab tab-block">
                      <li class="tab-item text-small {{selRegion == 'EU' ? 'active':''}}">
                        <a href="{{page}}#{{modal}}" (click)="selRegion='EU'">Шенген</a>
                      </li>
                      <li class="tab-item text-small {{selRegion == 'UCJ' ? 'active':''}}">
                        <a href="{{page}}#{{modal}}" (click)="selRegion='UCJ'">АНУ, Канад, Япон</a>
                      </li>
                      <li class="tab-item text-small {{selRegion == 'WW' ? 'active':''}}">
                        <a href="{{page}}#{{modal}}" (click)="selRegion='WW'">Бусад улс</a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div style="height: 350px; overflow-y: scroll; border: 1px solid #eee;padding: 10px">
                          <span *ngFor="let country of countries">
                            <label class="chip float-left mb-2" *ngIf="country.region == selRegion && !isselectedCountry(country)">{{country.name}}
                              <a href="{{page}}#{{modal}}" (click)="selCountries(country)" class="btn btn-clear btn-add" aria-label="" role="button"></a>
                            </label>
                          </span>
                    </div>
                  </div>
                  <div class="card-footer">
                        <span *ngFor="let country of newInvoice.countries">
                          <span class="chip bg-primary">
                            {{country.name}}
                            <a href="{{page}}#{{modal}}" (click)="removeCountries(country)" class="btn btn-clear" aria-label="" role="button"></a>
                          </span>
                        </span>
                  </div>
                </div>
              </div>

              <div *ngIf="pageId == 1" class="column col-6">
                <div class="card" style="height: 500px;">
                  <div class="card-body">
                    <div class="columns">
                      <div class="column col-12" *ngFor="let package of selService.packageList">
                        <div class="divider" style="margin-top: 5px;" [attr.data-content]="package.name"></div>
                        <div class="columns">
                          <div class="column col-{{12/package.protectList.length}}" *ngFor="let protect of package.protectList">
                            <div class="card">
                              <div class="card-body" style="min-height: 95px;">
                                <a href="{{page}}#{{modal}}" (click)="protectSelect(package.protectList, protect)" class="text-dark">
                                  <div class="text-small float-left" style="padding-right: 20px;">{{protect.descr}}</div>
                                  <figure class="avatar avatar-sm bg-secondary" style="position: absolute; margin-left: -20px;">
                                    <img src="assets/img/shield_blue.png" *ngIf="protect.state == 1"/>
                                  </figure>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="pageId == 2" class="column col-8">
                <div class="card">
                  <div class="card-body" style="height: 500px;">
                    <div class="divider" data-content="Даатгалын компаниуд"></div>
                    <div class="column col-3 col-sm-12" *ngIf="loading">
                      <div
                        class="loading float-left"
                        style="margin-left: 15px">

                      </div>
                    </div>
                    <div class="filter">
                      <div class="filter-body columns">
                        <div class="column col-4 filter-item"  *ngFor="let company of companies">
                          <div class="card" (click)="companySelect(company)" class="{{company.id == newInvoice.orgId ? 'active': ''}}">
                            <div class="card-header text-center">
                              <figure class="avatar bg-secondary">
                                <img src="assets/img/{{company.icon}}">
                              </figure>
                            </div>
                            <div class="card-body">
                              <div class="tile-title text-center text-small">{{company.name}}</div>
                              <div class="tile-subtitle text-small text-center text-bold">{{company.total | currency:company.currency+' '}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="pageId == 2" class="column col-4">
                <div class="card">
                  <div class="card-body" style="min-height: 500px;">
                    <span class="tile-subtitle text-bold text-small">Насны ангилал</span>
                    <div class="columns">

                      <div class="column col-12 input-group">
                        <span class="input-group-addon addon-sm" style="width:120px;">Бүлэг сонгох</span>
                        <select class="form-select select-sm" (change)="groupSelect()" [(ngModel)]="newInvoice.groupId">
                          <option [ngValue]="0">Үгүй</option>
                          <option *ngFor="let group of groups; let i = index;" [ngValue]="group.id">{{group.name}}</option>
                        </select>
                      </div>

                      <div class="column col-12 input-group">
                        <span class="input-group-addon addon-sm" style="width:120px;">0-2 нас</span>
                        <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age1)">&nbsp;-&nbsp;</button>
                        <input disabled class="form-input input-sm text-center" id="child_ticker" type="text" value="{{age1.length+''}}" toUppercase placeholder="0">
                        <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age1, 1)">&nbsp;+&nbsp;&nbsp;</button>
                      </div>
                      <div class="column col-12 input-group">
                        <span class="input-group-addon addon-sm" style="width:120px;">3-69 нас</span>
                        <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age2)">&nbsp;-&nbsp;</button>
                        <input disabled class="form-input input-sm text-center" id="person_ticker" type="text" value="{{age2.length+''}}" toUppercase placeholder="0">
                        <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age2, 2)">&nbsp;+&nbsp;</button>
                      </div>
                      <div class="column col-12 input-group">
                        <span class="input-group-addon addon-sm" style="width:120px;">70-79</span>
                        <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="popAges(age3)">&nbsp;-&nbsp;</button>
                        <input disabled class="form-input input-sm text-center" id="old_ticker" type="text" value="{{age3.length+''}}" toUppercase placeholder="0">
                        <button class="btn btn-primary input-group-btn btn-sm" style="width: 30px;" (click)="pushAges(age3, 3)">&nbsp;+&nbsp;</button>
                      </div>
                    </div>

                    <span class="tile-subtitle text-bold text-small">Зорчих хугацаа</span>
                    <div class="columns">
                      <div class="column col-12 input-group">
                        <span class="input-group-addon addon-sm" style="width: 120px;">Төрөл сонгох</span>
                        <select class="form-select select-sm" [(ngModel)]="newInvoice.daysType" (change)="rangeChange()">
                          <option [ngValue]="3">Нэг удаагийн</option>
                          <option [ngValue]="4">Бүтэн жилийн</option>
                        </select>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column col-12 input-group">
                        <span class="input-group-addon addon-sm" style="width: 120px;">Эхлэг өдөр</span>
                        <input type="date" class="form-input input-sm" [(ngModel)]="newInvoice.startDate" (change)="companies=[]; checkInvoice();" />
                      </div>
                      <div class="column col-12 input-group">
                        <span class="input-group-addon addon-sm" style="width: 120px;">Дуусах өдөр</span>
                        <input type="date" class="form-input input-sm" [(ngModel)]="newInvoice.endDate" (change)="companies=[]; checkInvoice();"/>
                      </div>
                    </div>

                    <span class="tile-title text-bold text-small text-primary">{{diffDays()}} өдөр</span>
                    <div class="divider"></div>
                    <button class="btn btn-primary btn-sm" (click)="checkInvoice()">Тооцоолол харах</button>
                  </div>

                </div>
              </div>

              <div *ngIf="pageId == 3 && selService.id == 1 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-8 col-xs-12">
                <div class="card" style="height: {{modalHeight}}px; overflow-y:scroll;">
                  <div class="card-body">
                    <span class="tile-subtitle text-bold text-small">Даатгуулагчид</span>
                    <a *ngIf="payresp.payStatus == 2" class="float-right btn btn-sm btn-success" style="width: 20%;" href="p/v1/cert/{{payresp.invoiceNo}}/0?uid={{payresp.uid}}" target="_blank"><i class="icon icon-link"></i>&nbsp;&nbsp;{{payresp.unit}}</a>
                    <div class="divider" *ngIf="age1.length>0" data-content="0-2 нас"></div>
                    <div class="columns" *ngFor="let age of age1; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                          <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 1)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                          <input class="form-input input-sm {{error['ln1'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                          <input class="form-input input-sm {{error['fn1'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                          <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" placeholder="" style="width: 60%;" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                          <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                          <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                        </div>
                      </div>
                    </div>

                    <div class="divider" *ngIf="age2.length>0" data-content="3-15 нас"></div>
                    <div class="columns" *ngFor="let age of age2; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                          <input class="form-input input-sm {{error['rd2'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 1)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                          <input class="form-input input-sm {{error['ln2'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                          <input class="form-input input-sm {{error['fn2'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                          <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" placeholder="" style="width: 60%;" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                          <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                          <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                        </div>
                      </div>
                    </div>

                    <div class="divider" *ngIf="age3.length>0" data-content="16-60 нас"></div>
                    <div class="columns" *ngFor="let age of age3; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                          <input class="form-input input-sm {{error['rd3'+i]}}" [(ngModel)]="age.rd" type="text" (change)="findUser(age, 2)" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                          <input class="form-input input-sm {{error['ln3'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                          <input class="form-input input-sm {{error['fn3'+i]}}"  [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                          <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ph" style="width: 60%;" type="phone" style="width: 60%;" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                          <button *ngIf="payresp.invoiceNo && age.id > 0" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2?'icon-edit':'icon-check'}}"></i></button>
                          <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                        </div>
                      </div>
                      <div class="column col-12">
                        <div class="divider"></div>
                      </div>
                    </div>

                    <div class="divider" *ngIf="age4.length>0" data-content="60-69 нас"></div>
                    <div class="columns" *ngFor="let age of age4; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                          <input class="form-input input-sm {{error['rd4'+i]}}" [(ngModel)]="age.rd" type="text" (change)="findUser(age, 2)" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                          <input class="form-input input-sm {{error['ln4'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                          <input class="form-input input-sm {{error['fn4'+i]}}"  [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                          <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ph" style="width: 60%;" type="phone" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                          <button *ngIf="payresp.invoiceNo && age.id > 0" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2?'icon-edit':'icon-check'}}"></i></button>
                          <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                        </div>
                      </div>
                      <div class="column col-12">
                        <div class="divider"></div>
                      </div>
                    </div>

                    <div class="divider" *ngIf="age5.length>0" data-content="70-79"></div>
                    <div class="columns" *ngFor="let age of age5; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                          <input class="form-input input-sm {{error['rd5'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 3)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                          <input class="form-input input-sm {{error['ln5'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                          <input class="form-input input-sm {{error['fn5'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                          <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" style="width: 60%;"  placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                          <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                          <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                        </div>
                      </div>
                    </div>

                    <div class="divider" *ngIf="age12.length>0" data-content="80-c дээш"></div>
                    <div class="columns" *ngFor="let age of age12; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр *</span>
                          <input class="form-input input-sm {{error['rd12'+i]}}" [(ngModel)]="age.rd" type="text" placeholder="" (change)="findUser(age, 12)" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Гадаад ПД *</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ep" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх *</span>
                          <input class="form-input input-sm {{error['ln12'+i]}}" [(ngModel)]="age.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр *</span>
                          <input class="form-input input-sm {{error['fn12'+i]}}" [(ngModel)]="age.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имейл</span>
                          <input class="form-input input-sm" [(ngModel)]="age.em" type="text" placeholder="" disabled="{{paylinks.qr != '' && age.status == 1}}">
                        </div>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Утас</span>
                          <input class="form-input input-sm" [(ngModel)]="age.ph" type="phone" style="width: 60%;"  placeholder="" toUppercase disabled="{{paylinks.qr != '' && age.status == 1}}">
                          <button *ngIf="payresp.invoiceNo" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="editInvoiceUser(age, i)"><i class="icon {{age.status != 2 ? 'icon-edit':'icon-check'}}"></i></button>
                          <button *ngIf="payresp.invoiceNo && age.status == 2" class="form-input btn btn-sm btn-block" style="width: 30px;" (click)="age.status = 1"><i class="icon icon-cross"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="float-right" *ngIf="listMode == 1">
                      <button class="btn btn-sm {{tabIndex == 1 ? 'btn-success':''}}" (click)="tabIndex = 1;"> 1 </button>
                      <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-success':''}}" (click)="tabIndex = 2;"> 2 </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="pageId == 3 && selService.id == 2" class="column col-8">
                <div class="card">
                  <div class="card-body" style="height: 500px; overflow-y:scroll;">
                    <div class="divider" *ngIf="newInvoice.properties.length>0" data-content="Хөрөнгийн мэдээлэл"></div>
                    <div class="columns" *ngFor="let prop of newInvoice.properties; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-5">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 110px;">Гэрчилгээ №:</span>
                          <span class="form-input input-sm">{{prop.regno}}</span>
                        </div>
                      </div>
                      <div class="column col-3">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 65px;">Талбай:</span>
                          <span class="form-input input-sm">{{prop.size}} м2</span>
                        </div>
                      </div>
                      <div class="column col-4">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Төрөл:</span>
                          <span class="form-input input-sm">{{propertyCat(prop.cat)}}</span>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Ашиглалтанд:</span>
                          <span class="form-input input-sm">{{prop.propYear}}</span>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Үнэлгээ:</span>
                          <span class="form-input input-sm">{{prop.amount | currency : '₮ '}}</span>
                        </div>
                      </div>
                      <div class="column col-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Хаяг:</span>
                          <span class="form-input input-sm">{{prop.address}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="divider" data-content="Эзэмшигчдийн мэдээлэл"></div>

                    <div class="columns" *ngFor="let user of owners; let i = index;">
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Регистр №:</span>
                          <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="user.rd" type="text" placeholder=""  toUppercase disabled="{{paylinks.qr}}">
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Эцэг/эх нэр:</span>
                          <input class="form-input input-sm" [(ngModel)]="user.ln" type="text" placeholder="" toUppercase disabled="{{paylinks.qr}}">
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Нэр:</span>
                          <input class="form-input input-sm" [(ngModel)]="user.fn" type="text" placeholder="" toUppercase disabled="{{paylinks.qr}}">
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 100px;">Имэйл:</span>
                          <input class="form-input input-sm {{error['em1'+i]}}" [(ngModel)]="user.em" type="email" placeholder="" toUppercase disabled="{{paylinks.qr}}">
                        </div>
                      </div>
                      <div class="column col-6 input-group">
                        <span class="input-group-addon addon-sm" style="width: 80px;">Утас:</span>
                        <input class="form-input input-sm" type="text" value="" [(ngModel)]="user.ph" toUppercase disabled="{{paylinks.qr}}"/>
                      </div>
                      <div class="column col-6 input-group">
                        <span class="input-group-addon addon-sm" style="width: 90px;">Эзэмшигч:</span>
                        <select class="form-select input-group select-sm" [(ngModel)]="user.ownerType" disabled="{{paylinks.qr}}">
                          <option value="1" [ngValue]="1">Тийм</option>
                          <option value="2" [ngValue]="2">Үгүй</option>
                        </select>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div *ngIf="pageId == 3 && selService.id == 3 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-8 col-xs-12">
                <div class="card" style="height: {{modalHeight}}px;">
                  <div class="card-body" style="overflow-y:scroll;">
                    <div class="divider" *ngIf="newInvoice.properties.length>0" data-content="Тээврийн хэрэгслийн мэдээлэл"></div>
                    <div class="columns" *ngFor="let prop of newInvoice.properties; let i = index;" style="margin-bottom: 10px">
                      <div class="column col-3 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Улсын №:</span>
                          <input type="text" class="form-input input-sm" [(ngModel)]="prop.regno"/>
                        </div>
                      </div>
                      <div class="column col-4 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 85px;">Арлын №:</span>
                          <span class="form-input input-sm disabled">{{prop.vin}}</span>
                        </div>
                      </div>
                      <div class="column col-5 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 120px;">Марк/Модел:</span>
                          <span class="form-input input-sm disabled">{{prop.mark}}/{{prop.model}}</span>
                        </div>
                      </div>
                      <div class="column col-4 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Үнэлгээ:</span>
                          <span class="form-input input-sm">{{prop.amount | currency : '₮ '}}</span>
                        </div>
                      </div>
                      <div class="column col-3 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Эхлэх:</span>
                          <span class="form-input input-sm">{{newInvoice.startDate | date : 'yyyy-MM-dd'}}</span>
                        </div>
                      </div>
                      <div class="column col-3 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 80px;">Хугацаа:</span>
                          <span class="form-input input-sm disabled">{{newInvoice.days}} өдөр</span>
                        </div>
                      </div>
                      <div class="column col-2 col-xs-12">
                        <button class="btn btn-sm btn-primary input-group-btn" (click)="propUpdate(prop)">Шинэчлэх</button>
                      </div>
                      <div class="column col-6 col-xs-12">
                        <div class="input-group">
                          <a *ngIf="payresp.payStatus == 2" class="float-right btn btn-sm btn-error" href="p/v1/cert/{{payresp.invoiceNo}}/0?uid={{payresp.uid}}" target="_blank"><i class="icon icon-link"></i>&nbsp;&nbsp;Баталгааны хуудас</a>
                          <a *ngIf="payresp.payStatus == 2" class="float-right btn btn-sm btn-primary ml-2" href="p/contract/{{payresp.serviceId}}/{{payresp.orgId}}" target="_blank"><i class="icon icon-link"></i>&nbsp;&nbsp;Гэрээ татах</a>
                        </div>
                      </div>
                    </div>

                    <div class="divider" data-content="Өмчлөгчийн мэдээлэл"></div>

                    <div class="columns" *ngFor="let user of owners; let i = index;">
                      <div class="column col-4 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 40px;">РД:</span>
                          <input class="form-input input-sm {{error['rd1'+i]}}" [(ngModel)]="user.rd" (change)="regexRegnoChange(user.rd, i, user)" type="text" placeholder=""  toUppercase disabled="{{newInvoice.id > 0}}">
                        </div>
                      </div>
                      <div class="column col-4 col-xs-12" *ngIf="user.rd.length != 7">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Овог:</span>
                          <input class="form-input input-sm {{error['ln1'+i]}}" [(ngModel)]="user.ln" type="text" (change)="regexCyrillChange(user.ln, 'ln1'+i)" placeholder="" toUppercase disabled="{{newInvoice.id > 0}}">
                        </div>
                      </div>
                      <div class="column col-4 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 60px;">Нэр:</span>
                          <input class="form-input input-sm {{error['fn1'+i]}}" [(ngModel)]="user.fn" type="text" (change)="regexCyrillChange(user.fn, 'fn1'+i)" placeholder="" toUppercase disabled="{{newInvoice.id > 0}}">
                        </div>
                      </div>
                      <div class="column col-4 col-xs-12">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 65px;">Имэйл:</span>
                          <input class="form-input input-sm {{error['em1'+i]}}" [(ngModel)]="user.em" type="email" placeholder="" toUppercase>
                        </div>
                      </div>
                      <div class="column col-4 col-xs-12">
                        <div class="input-group">
                          <span class="input-group-addon addon-sm" style="width: 60px;">Утас*:</span>
                          <input class="form-input input-sm" type="number" value="" [(ngModel)]="user.ph"/>
                        </div>
                      </div>
                      <div class="column col-4 col-xs-12 input-group">
                        <span class="input-group-addon addon-sm" style="width: 60px;">Төрөл:</span>
                        <select class="form-select input-group select-sm" [(ngModel)]="user.ownerType" disabled>
                          <option value="1" [ngValue]="1" value="1">Эзэмшигч</option>
                          <option value="2" [ngValue]="2" value="2">Өмчлөгч</option>
                        </select>
                      </div>
                      <div class="column col-12 col-xs-12" *ngIf="i == 0">
                        <label class="form-switch text-small">
                          <input type="checkbox" (change)="setOwnerGetter()">
                          <i class="form-icon"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Эзэмшигч өмчлөгч ижил
                        </label>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column col-12 col-xs-12">
                        <div class="input-group">
                          <input class="form-input input-group-addon addon-sm input-sm" type="file"
                                 multiple (change)="selectImageFiles($event)">
                          <button class="btn btn-primary input-group-btn" (click)="uploadPics()">Хадгалах</button>
                        </div>

                        <div class="divider text-center" data-content="ЗУРАГУУД"></div>
                        <div class="columns">
                          <div class="column col-12 col-xs-12">
                            <div class="filter">
                              <div class="filter-body columns">
                                <div class="column col-4 filter-item" *ngFor="let img of fileInfos" >
                                  <div class="card">
                                    <div class="card-image" (click)="openImage(img)"><img class="img-responsive" src="p/img/{{img.url}}" style="height: 120px !important;"></div>
                                    <div class="card-body p-1"><a class="btn btn-sm btn-primary" (click)="deleteImage(img)">Устгах</a></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="float-right" *ngIf="listMode == 1">
                      <button class="btn btn-sm {{tabIndex == 1 ? 'btn-error':''}}" (click)="tabIndex = 1;"> 1 </button>
                      <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-error':''}}" (click)="tabIndex = 2;"> 2 </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="pageId == 3 && selService.id == 5 && (listMode == 2 || (listMode == 1 && tabIndex == 1))" class="column col-8 col-xs-12">
                <div class="card">
                  <div class="card-body" style="height: 600px; overflow-y:scroll;">
                    <div class="divider" data-content="Даатгуулагчийн мэдээлэл"></div>

                    <div class="columns" style="margin-bottom: 10px">
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">{{newInvoice.region == 'A1'?'Регистр №:':'Пасспорт №:'}}</span>
                          <input class="form-input input-sm" [(ngModel)]="newInvoice.local.passNo" type="text" placeholder="" style="width:120px;" toUppercase disabled>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Овог :</span>
                          <input class="form-input input-sm" [(ngModel)]="newInvoice.local.lastName" type="text" placeholder="" toUppercase disabled>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Нэр :</span>
                          <input class="form-input input-sm" [(ngModel)]="newInvoice.local.firstName" type="text" placeholder="" toUppercase disabled>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Утас :</span>
                          <input class="form-input input-sm" [(ngModel)]="newInvoice.local.phoneNo" type="text" placeholder="" toUppercase disabled>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 130px;">Төрсөн огноо:</span>
                          <input class="form-input input-sm" [(ngModel)]="newInvoice.local.birthDate" type="email" placeholder="" toUppercase disabled>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 160px;">Хамгаалалтын дүн:</span>
                          <span class="form-input input-sm">{{newInvoice.local.coverage | currency: 'MNT '}}</span>
                        </div>
                      </div>
                      <div class="column col-6">
                        <div class="input-group"><span class="input-group-addon addon-sm" style="width: 190px;">Даатгалын хугацаа (өдөр):</span>
                          <span class="form-input input-sm">{{newInvoice.days}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="pageId == 3 && (listMode == 2 || (listMode == 1 && tabIndex == 2))" class="column col-4 col-xs-12">
                <div class="card" style="height: {{modalHeight}}px;">
                  <div class="card-body" >
                    <div class="divider" data-content="Төлбөрийн нэхэмжлэх"></div>

<!--                    <img style="width: 180px; margin: 0px;" src="data:image/png;base64, {{paylinks.qr}}" *ngIf="paylinks.qr && paylinks.qr"/>-->

                    <div class="p-1" *ngIf="paylinks.qr">
                      <div class="tile-title text-small text-gray"># {{payresp.invoiceNo}}</div>
                      <div class="tile-title text-small text-bold">{{payresp.orgName}}</div>
                      <div class="tile-title text-small" *ngIf="payresp.startDate">{{payresp.startDate | date:'yyyy-MM-dd'}} / {{payresp.endDate | date:'yyyy-MM-dd'}}</div>

                      <div class="tile-title text-small">{{paylinks.unit}} (хүн) / {{payresp.days}} (өдөр)</div>
                      <div class="tile-title text-small">
                        <span *ngFor="let c of payresp.countries">{{c.name}}, </span>
                      </div>
                      <div class="tile-title text-small">{{paylinks.bankName}} / {{paylinks.accountName}} / {{paylinks.accountNo}}</div>
                      <div class="tile-title text-small text-bold">{{paylinks.payNo}} (гүйлгээний утга)</div>

                      <div class="tile-title text-small">{{paylinks.amountUSD | currency : '$'}} (Ханш : {{paylinks.rate | currency : '₮'}})</div>

                      <div class="tile-title text-bold">{{paylinks.finalAmount | currency : '₮'}}
                        <span *ngIf="paylinks.percent>0">/ {{paylinks.amount | currency : '₮'}} ({{paylinks.percent}}%)</span></div>

                      <div class="tile-title text-success text-small">Төлөгдсөн</div>
                      <div class="divider"></div>
<!--                      <button class="btn btn-block btn-sm mt-2 col-12" *ngIf="!payLoading1" (click)="downloadInvoice(paylinks.invoiceNo)">Нэхэмжлэх татах</button>-->
<!--                      <div class="clearfix"></div>-->
                      <button class="mt-2 col-12 btn {{payresp.certSent == 1 ? 'btn-warning':''}} btn-sm float-left" *ngIf="payresp.payStatus == 2" (click)="sentPdfs(payresp)" style="margin-right: 15px;">Даатгуулагч руу илгээх <i class="icon icon-mail"></i></button>
                      <div class="clearfix"></div>
                      <button class="mt-2 col-12 btn {{payresp.xlsSent == 1 ? 'btn-warning':''}} btn-sm float-left" *ngIf="payresp.payStatus == 2" (click)="sendXls(payresp)" style="margin-right: 15px;">{{payresp.orgName.replace('даатгал','')}} руу илгээх <i class="icon icon-mail"></i></button>
                      <div class="clearfix"></div>
                      <button class="mt-2 col-12 btn {{payresp.xlsSent == 1 ? 'btn-warning':''}} btn-sm float-left" *ngIf="payresp.payStatus == 2" (click)="getXls(payresp)" style="margin-right: 15px;">Жагсаалт татах <i class="icon icon-download"></i></button>
                      <div class="clearfix"></div>

                      <div *ngIf="payLoading">
                        <div class="loading float-left" style="margin: 5px;"></div>
                      </div>
                    </div>
                    <div *ngIf="loading">
                      <div class="loading float-left" style=""></div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="float-right" *ngIf="listMode == 1">
                      <button class="btn btn-sm {{tabIndex == 1 ? 'btn-error':''}}" (click)="tabIndex = 1;"> 1 </button>
                      <button class="btn btn-sm ml-1 {{tabIndex == 2 ? 'btn-error':''}}" (click)="tabIndex = 2;"> 2 </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="pageId == 4" class="column col-12">
                <div class="card" style="height: {{modalHeight}}px;">
                  <div class="card-body">
                    <div class="divider" data-content="Гэрээ"></div>
                    <div *ngIf="payLoading">
                      <div
                        class="loading float-left"
                        style="margin-left: 20px">

                      </div>
                    </div>

                    <div class="filter">
                      <div class="filter-body columns">
                        <div class="column col-3 filter-item" *ngFor="let member of payresp.users">
                          <div class="card">
                            <div class="card-header text-center">
                              <figure class="avatar bg-secondary avatar-sm">
                                <img src="assets/img/gamer.png">
                              </figure>
                            </div>
                            <div class="card-body">
                              <div class="tile-title text-center text-small">{{member.regno}}</div>
                              <div class="tile-subtitle text-small text-center text-bold">{{member.lastname}} {{member.firstname}}</div>
                              <div class="tile-subtitle text-center text-small"><a class="text-small text-center" href="p/v1/pdf/{{payresp.invoiceNo}}/{{member.id}}" target="_blank">Гэрээг татах</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

</body>
</html>
